import React, { ErrorInfo } from 'react'
import { removePersistedState } from '../../FSM/util'
import { Box, Typography, Paper, CssBaseline, ThemeProvider } from '@material-ui/core'
import brandTheme from 'BrandTheme'
import { getFlowName, getProductName } from '../../util'

type ErrorBoundaryStateType = { error: Error; errorInfo: ErrorInfo }

const isStateError = (error: string) =>
  error.search(/Error: Child state '(.+)' does not exist on '(.+)'/i) !== -1

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryStateType = {
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // FSM missing state error case
    if (isStateError(error.toString())) {
      // Remove FSM cache and reload page
      removePersistedState()
      window.location.reload()
    }
  }

  render() {
    const { errorInfo, error = {} } = this.state
    const { code, message } = (error as unknown as Record<string, string>) || {}
    const productName = getProductName()
    const flowName = getFlowName()

    if (errorInfo && message) {
      return (
        <ThemeProvider theme={brandTheme.theme}>
          <CssBaseline />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100vh'
            bgcolor='#f0f0f0'
          >
            <Paper elevation={3} style={{ padding: 32, maxWidth: 600, textAlign: 'center' }}>
              <Typography variant='h3' component='h3' gutterBottom>
                Error: {code}
              </Typography>
              <Typography variant='h5' component='h5' gutterBottom>
                {message}
              </Typography>
              <a href={`/?flow=${flowName}&product=${productName}`}>Get back to Home Page</a>
            </Paper>
          </Box>
        </ThemeProvider>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
